<template>
  <div class="blog-category">
    <div class="container">
      <header class="blog-category__header">
        <base-heading
          bold="bold"
          align="center"
          class="blog-category__header-title"
        >
          {{ $t('blog.title') }}
        </base-heading>

        <ul class="blog-category__categories">
          <li
            class="blog-category__categories-item"
            v-for="category in blogCategories"
            :key="category.id"
          >
            <button
              class="blog-category__categories-button"
              :class="[
                category.id === activeCategoryID ? 'blog-category__categories-button--active' : false,
              ]"
              @click="selectCategory(category)"
              :title="category.data.name"
              :aria-label="category.data.name"
            >
              {{ category.data.name }}
            </button>
          </li>
        </ul>
      </header>

      <section class="blog-category__content">
        <ul class="blog-category__grid" v-if="posts && posts.length">
          <blog-post
            v-for="(post, index) in posts"
            :key="post.id"
            :mode="index === 0 ? 'full' : 'normal'"
            :class="[
              index === 0 ? 'blog-category__grid-item--full' : false,
            ]"
            :main-image="post.data.main_image.url"
            :content="post.data.short_description"
            :title="post.data.title[0].text"
            :publish-date="post.last_publication_date"
            :slug="post.uid"
            :categories="post.data.categories"
            @filter-by-category="selectCategory($event)"
            class="blog-category__grid-item"
          />
        </ul>

        <p class="blog-category__error" v-else>
          {{ $t('blog.no-results') }}
        </p>
      </section>

      <footer class="blog-category__actions" v-if="posts && posts.length">
        <base-pagination
          class="blog-category__actions-pagination"
          :total="totalItems"
          :current="activePage"
          :per-page="pageSize"
          :page-range="pageRange"
          @page-change="changePage($event)"
        />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogCategory',
  data: () => ({
    posts: [],
    categories: [],
    activeCategory: null,
    activePage: 1,
    pageSize: 5,
    pageRange: 2,
    totalPages: 1,
    totalItems: 1,
  }),
  computed: {
    activeCategoryID() {
      return this.activeCategory !== null ? this.activeCategory.id : null;
    },
    blogCategories() {
      return [{
        data: { name: 'Default' },
        id: null,
      }, ...this.categories];
    },
  },
  methods: {
    changePage(page) {
      this.activePage = page;
      this.fetchPosts();
    },
    async getInitialCategories() {
      try {
        const response = await this.$prismic.client.query(
          this.$prismic.Predicates.at('document.type', 'category'),
        );

        return response;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    async getInitialPosts() {
      try {
        const response = await this.$prismic.client.query(
          this.$prismic.Predicates.at('document.type', 'blog_post'),
          { pageSize: this.pageSize, page: this.activePage },
        );

        return response;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    resetFilters() {
      this.activePage = 1;
    },
    selectCategory(category) {
      this.activeCategory = category;
      this.activePage = 1;
      this.fetchPosts();
    },
    async fetchPosts() {
      this.$store.dispatch('ui/setPreloader', true);

      const filters = [
        this.$prismic.Predicates.at('document.type', 'blog_post'),
        this.activeCategoryID !== null
          ? this.$prismic.Predicates.at('my.blog_post.categories.category', this.activeCategoryID)
          : '',
      ];

      const sorting = {
        pageSize: this.pageSize,
        page: this.activePage,
      };

      try {
        const response = await this.$prismic.client.query([...filters], { ...sorting });

        this.totalPages = response.total_pages;
        this.totalItems = response.total_results_size;
        this.posts = response.results;
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          this.$store.dispatch('ui/setPreloader', false);
        }, 300);
      }
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const [categories, posts] = await Promise.all([
        this.getInitialCategories(),
        this.getInitialPosts(),
      ]);

      this.totalPages = posts.total_pagesl;
      this.totalItems = posts.total_results_size;
      this.posts = posts.results;
      this.categories = categories.results;
    } catch (err) {
      console.log(err);
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" src="./BlogCategory.scss" />
